












import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {
    OParticipantSearch: () =>
      import(
        "@/components/organisms/event/participant/o-participant-search.vue"
      ),
  },
  setup() {
    return {};
  },
});
